<template>
  <div
    class="cardAnalysis grid gap-8 mt-8 h8:grid-cols-3 grid-cols-1 text-center items-center"
  >
    <div
      class="flex flex-col items-center flex-grow gap-2 k1:gap-5 rounded bg-white p-3 py-4 shadow-lg h-full justify-center"
      v-for="(section, index) in sections"
      :key="section.title"
    >
      <div class="icon flex items-center justify-center flex-shrink-0">
        <img :src="section.img" class="object-contain w-32 aspect-square" />
      </div>
      <div class="text flex-grow">
        <div class="title heading3 font-bold !text-center">
          {{ section.title }}
        </div>
        <div class="text-sm">
          {{ section.text }}
        </div>

        <section class="mt-4">
          <div v-if="index < 3" class="">
            <button
              class="gradientText hoverUnderline"
              @click="openPage(section.path)"
            >
              Read more
            </button>
          </div>
          <div v-else class="italic mt-4 font-semibold">Coming soon</div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { sections } from "@/data/analyseSectionData";
import { useRouter } from "vue-router";

export default {
  name: "CardAnalysis",
  components: {},
  setup() {
    const router = useRouter();
    const openPage = (path) => {
      if (path) router.push(path);
    };

    return { sections, openPage };
  },
};
</script>

<style scoped></style>
